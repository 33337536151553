export const SupportedPrimitivesWithoutNullArray = [
  'boolean',
  'string',
  'number',
] as const;

// export type SupportedPrimitives = typeof SupportedPrimitivesArray[number];
export type SupportedPrimitive = boolean | string | number | null | undefined;

export const SupportedTypesArray = [
  ...SupportedPrimitivesWithoutNullArray,
  'object',
] as const;

export type SupportedArray = Array<SupportedTypes>;
// export interface SupportedObject {
//   [key: string | number]: SupportedTypes;
// };
export interface SupportedObject extends Record<string | number, SupportedTypes> { }

export type SupportedTypes = SupportedPrimitive | SupportedObject | SupportedArray;
