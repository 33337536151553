import { EcomStyle, Event, EventAdmin, EventMember, Look } from "common-types";
import { EVENT_ITEM_ID_KEYS, EventItemIdKeys } from "../constants";
import { ChangeType } from "./change-type.type";
import { Current, Previous } from './dif.type';
import { SupportedObject, SupportedPrimitive, SupportedTypes } from "./supported-types.type";

export type AddChange<T extends SupportedTypes, ObjectKeys extends string[] = []> = {
  previous: typeof ChangeType.didNotExist,
  current: Partial<MappedTypes<T, ObjectKeys>>,
}

export type RemoveChange<T extends SupportedTypes, ObjectKeys extends string[] = []> = {
  previous: Partial<MappedTypes<T, ObjectKeys>>,
  current: typeof ChangeType.removed,
}

export type UpdateChange<T extends SupportedTypes, ObjectKeys extends string[] = []> = {
  previous: Partial<MappedTypes<T, ObjectKeys>>,
  current: Partial<MappedTypes<T, ObjectKeys>>,
}

/**
 * A change that represents a difference between two objects.
 * It will contain a {@link Previous previous} and {@link Current current} property that represent the previous and current values of the object.
 * The previous and current properties will only contain the properties that were added, removed, or updated.
 * @template T - The type of the object that the Change represents a difference for.
 */
export type Change<T extends SupportedTypes, ObjectKeys extends string[] = []> = AddChange<T, ObjectKeys> | RemoveChange<T, ObjectKeys> | UpdateChange<T, ObjectKeys>;


/** This is necessary for mapping all of the supported types to the final structure of the previous or current properties in a {@link Change} object
 * @template T - The type of the object that the MappedTypes represents a difference for.
 * @template ObjectKeys - An array of keys that can be used to map an object array to an object.  It represents a list of itemIdKeys.
 * For example, a MappedType<EventMember, ['id']> would map an array of EventMember objects to an object whose key is the "id" of the EventMember object and whose value is the EventMember object.
 * It accepts an array of keys because there might be further nested objects. For example, MappedType<Event, ['id', 'styleCode']
*/
export type MappedTypes<T, ObjectKeys extends string[] = []> =
  T extends undefined ? never
  : T extends SupportedPrimitive ? T // T is a primitive, return T
  : T extends null ? never
  : T extends number ? number
  : T extends string ? string
  : T extends boolean ? boolean
  : T extends SupportedObject // if T is an object
  ? { [K in keyof T]: MappedTypes<T[K], ObjectKeys> } // map each key to MappedTypes
  : T extends Array<infer J> // if T is an array
  ? J extends SupportedPrimitive // if T is an array of primitives
  ? ArrayToObject<J, ObjectKeys>
  : J extends SupportedObject // if T is an array of objects
  ? ArrayToObject<J, ObjectKeys> // map to object with keys as values of key in object
  : J extends Array<Array<any>> // if T is an array of arrays
  ? never // not supported
  : never
  : T // return T

/** Utility type for mapping arrays to objects using either the {@link IndexMap} or {@link PropertyMap} types. */
type ArrayToObject<T extends SupportedObject | SupportedPrimitive, ObjectKeys extends string[] = []> =
  T extends SupportedObject
  ? Extract<keyof T, ObjectKeys[number]> extends never ? IndexMap<T> | PropertyMap<T, keyof T>
  : PropertyMap<T, Extract<keyof T, ObjectKeys[number]>>
  : IndexMap<T>;

/** Maps an item in an array to an object whose key is the item's index and whose value is the item's value.  */
type IndexMap<T extends SupportedObject | SupportedPrimitive> =
  T extends SupportedObject ? {
    [P in keyof T as `${P & number}`]: T
  } : {
    [P in number]: T
  };

/** Maps an item in an object array to an object whose key is a specific property from the object
* and whose value is the object itself.
* @template T The type of the item in the array (should be a SupportedObject because this is used for mapping object arrays).
* @template K The property of the item that will be mapped to the key of the new object.
*  */
type PropertyMap<T extends SupportedObject, K extends keyof T> = {
  [P in T[K] & string]: T
};


export type EventDifChange = Change<Event, [EventItemIdKeys]>;
export type MembersDifChange = Change<EventMember, [typeof EVENT_ITEM_ID_KEYS['members']]>;
export type AdminsDifChange = Change<EventAdmin, [typeof EVENT_ITEM_ID_KEYS['admins']]>;
export type LooksDifChange = Change<Look, [typeof EVENT_ITEM_ID_KEYS['looks']]>;
export type EcomStylesDifChange = Change<EcomStyle, [EventItemIdKeys]>;




/// EXAMPLES
type Item = {
  id: string;
  name: string;
};

const items: Item[] = [
  { id: 'a1', name: 'Item 1' },
  { id: 'b2', name: 'Item 2' },
];

type ItemsIndexMap = IndexMap<Item>;
const indexMapped: ItemsIndexMap = {
  0: { id: 'a1', name: 'Item 1' },
  1: { id: 'b2', name: 'Item 2' },
};

type ItemsPropertyMap = PropertyMap<Item, 'id'>;
const propertyMapped: ItemsPropertyMap = {
  a1: { id: 'a1', name: 'Item 1' },
  b2: { id: 'b2', name: 'Item 2' },
};

type MembersMap = PropertyMap<EventMember, 'id'>;
type AdminsMap = PropertyMap<EventAdmin, 'id'>;
type LooksMap = PropertyMap<Look, 'id'>;
type EcomStylesMap = PropertyMap<EcomStyle, 'styleCode'>;

const EventChange: EventDifChange = {
  previous: {
    eventName: 'Wedding',
    members: {
      'as': { id: 'a1', memberRole: 'Groom' },
      'b2': { id: 'b2', memberRole: 'Best Man' },
    } satisfies MembersMap,

  },
  current: ChangeType.removed,

}


