import { Event } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';

export function getMembersDifFromEvent<T extends Event | undefined>(previous: T, current: T, options?: DifOptions<'members'>) {

  // return getDif(previous.members, current.members, eventItemIdKeys['members'], eventItemIdKeys, 0, 'members', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);
  return getDifByKeyFromEvent(previous, current, 'members', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const event: Event = {} as Event;

// getMembersDifFromEvent(event, event, { keysToIgnore: ['memberLook'] });

