export const EVENT_ITEM_ID_KEYS = {
  'altColors': 'styleCode',
  'admins': 'id',
  // 'images': 'mannequinImage',
  'mannequinImages': 'mannequinImage',
  'looks': 'id',
  'members': 'id',
  'memberLook': 'id',
  'styles': 'styleCode',
  'relatedStyles': 'styleCode',
  'matchingStyles': 'styleCode',
} as const;

// map to the values of the keys in the above object
export type EventItemIdKeys = typeof EVENT_ITEM_ID_KEYS[keyof typeof EVENT_ITEM_ID_KEYS];

