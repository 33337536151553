import { ChangeType, SupportedTypes } from '../types';
import { DifOptions } from '../types/dif-options.type';
import { getLevelSpacers } from './get-level-spacers';
import { isSupportedArray, isSupportedObject, isSupportedObjectArray, isSupportedPrimitive, isSupportedSimpleArray } from './is-supported-type';

/** Do not use this type yet.  Only using for debugging */
type GetDifType = ReturnType<typeof getDif>;


/**
 * This function is the main entry point for the dif algorithm.  It will determine the type of the previous and current values and call the appropriate dif algorithm.
 * It is also called recursively by the getObjectDif and getArrayDif functions to handle nested objects and arrays.
 * @param previous the previous value to compare
 * @param current the current value to compare
 * @param recursionDepth the current recursion depth
 * @param recursionKey the current recursion key
 * @param options DifOptions
 * @returns
 */
export function getDif<T extends SupportedTypes>(
  previous: T,
  current: T,
  itemIdKey: string | undefined = undefined,
  difAlgorithmItemIdKey: Record<string, string> = {},
  recursionDepth = 0,
  recursionKey: string,
  options: DifOptions
) {

  try {
    const { ignoreMeta, logDifs, logNoDif, logChecks, logSimpleDifs, keysToInclude, keysToIgnore } = options;


  /// Since recursionKey is a period-separated string with each part representing a key in the object (starting from the top of the object),
  /// we need to get the last key in the string find our current key.
  const recursionKeyAtCurrentDepth = recursionKey.split('.').slice(-1)[0];
    const rootKey = recursionKey.split('.')[1];

    if (shouldSkipKey(keysToInclude, keysToIgnore, ignoreMeta, recursionDepth, recursionKeyAtCurrentDepth, rootKey, logChecks)) {
      return null;
    }

  /* if (keysToInclude && recursionDepth === 1 && !keysToInclude.includes(rootKey as keyof DifOptions['keysToInclude'])) {
    if (logChecks) console.log(recursionKeyAtCurrentDepth, '-- skipping key 🙈');
    return null;
  }

  if (keysToIgnore && keysToIgnore.includes(recursionKeyAtCurrentDepth as keyof DifOptions['keysToIgnore'])) {
    // console.log("keysToIgnore: ", keysToIgnore);
    if (logChecks)
      console.log(recursionKeyAtCurrentDepth, '-- skipping key 🙈 ');
    return null;
  }


  if (ignoreMeta && (recursionKeyAtCurrentDepth === 'lastUpdated' || recursionKeyAtCurrentDepth === 'update_time' || recursionKeyAtCurrentDepth === 'lastUpdatedBy')) {
    if (logChecks) console.log(recursionKeyAtCurrentDepth, '-- skipping metadata');
    return null;
  } */


    /*
    firstName
    lastName
    role
    isOrganizer
    inviteAccepted
    phone
    name
    id
    email
    */

  const logKey = recursionDepth === 0 ? recursionKey
    // remove the first key from recursionKey
    : recursionKey.split('.').slice(1).join('.');


    // console.log({ recursionDepth, recursionKeyAtCurrentDepth, rootKey });
    // console.log("typeof previous: ", typeof previous, "typeof current: ", typeof current);





    if (current === undefined || previous === undefined) {

      if (current === undefined && previous === undefined) {
        if (logNoDif) console.log("Both current and previous are undefined for", recursionKey);
        return null;
      }

      if (current === undefined && previous !== undefined) {
        if (logDifs) console.log(recursionKey, "was removed", { current, previous });
        return {
          current: ChangeType.removed,
          previous
        };
      }

      if (current !== undefined && previous === undefined) {
        if (logDifs) console.log(recursionKey, "was added", { current, previous });
        return {
          current,
          previous: ChangeType.didNotExist
        };
      }

      // This should never happen, but TS can't track the separate checks above
      if (current === undefined || previous === undefined) {
        console.warn("We should never get here", { current, previous });
        return null;
      }
    } /// end of undefined check

    if (logChecks) {
      console.log(getLevelSpacers(recursionDepth) + logKey, "checking...", { previous, current });
    }

    const isPrimitive = isSupportedPrimitive(current) && isSupportedPrimitive(previous)
      || (isSupportedPrimitive(current) && previous === undefined)
      || (isSupportedPrimitive(previous) && current === undefined);
    if (isPrimitive) { /// BASE CASE
      if (current !== previous) {
        if (logSimpleDifs) console.log(recursionKey, "was changed", { current, previous });
        return { current, previous };
      }

      if (logNoDif) console.log("No dif found");
      return null;
    } /// end of primitive check




    const currentIsObject = isSupportedObject(current);
    const previousIsObject = isSupportedObject(previous);
    const isObject = (currentIsObject && previousIsObject) || (currentIsObject && previous === undefined) || (previousIsObject && current === undefined);
    const currentIsArray = isSupportedArray(current);
    const previousIsArray = isSupportedArray(previous);
    const isArray = (currentIsArray && previousIsArray) || (currentIsArray && previous === undefined) || (previousIsArray && current === undefined);

    if (!isObject && !isArray) {
      console.warn(recursionKey, 'not a supported type', { currentType: typeof current, previousType: typeof previous });
      return null;
    }

    /// ********************** Handle Arrays ********************** ///
    if (isArray) {
      /// ***** Simple Arrays ***** ///
      if (isSupportedSimpleArray(current) && isSupportedSimpleArray(previous)) {
        const dif = {
          previous: Object.create(null),
          current: Object.create(null),
        }
        for (let i = 0; i < current.length; i++) {
          const currentItem = current[i];
          const previousItem = previous[i];
          const itemDif = getDif(previousItem, currentItem, undefined, undefined, recursionDepth + 1, `${recursionKey}.${i}`, options);
          if (itemDif !== null) {
            dif.current[i] = itemDif.current ?? ChangeType.removed;
            dif.previous[i] = itemDif.previous ?? ChangeType.didNotExist;
          }
        }
        if (previous.length > current.length) {
          for (let i = current.length; i < previous.length; i++) {
            const previousItem = previous[i];
            dif.current[i] = ChangeType.removed;
            dif.previous[i] = previousItem ?? ChangeType.didNotExist;
          }
        }
        if (Object.keys(dif.current).length === 0 && Object.keys(dif.previous).length === 0) {
          if (logNoDif) console.log("No dif found");
          return null;
        }
        return dif;
      } /// end of simple array check

      /// ***** Object Arrays ***** ///
      if (isSupportedObjectArray(current) && isSupportedObjectArray(previous)) {
        const dif = {
          previous: Object.create(null),
          current: Object.create(null),
        };
        for (let i = 0; i < current.length; i++) {
          const currentItem = current[i];
          const validItemIdKey = itemIdKey !== undefined && itemIdKey in currentItem;
          const matchingItems = validItemIdKey ? previous.filter(item => item[itemIdKey] === currentItem[itemIdKey]) : null;
          if (matchingItems && validItemIdKey && matchingItems.length > 1) {
            console.warn('Multiple items found with the same', itemIdKey, ":", currentItem[itemIdKey], "in", recursionKey, "at index", i, ". Skipping check for differences.", currentItem);
            // since we can't evaluate if there is a difference, we will just skip this item
            continue;
          }

          const previousItem = validItemIdKey && matchingItems ? matchingItems[0] : previous[i];
          const currentItemId = validItemIdKey ? currentItem[itemIdKey] as string : i;
          const id = validItemIdKey ? currentItemId : i;
          if (id === undefined) {
            console.warn("id is undefined");
          }
          const itemDifItemIdKey = difAlgorithmItemIdKey[id as keyof typeof difAlgorithmItemIdKey] ?? undefined;
          const itemDif = getDif(previousItem, currentItem, itemDifItemIdKey, difAlgorithmItemIdKey, recursionDepth + 1, `${recursionKey}.${currentItemId}`, options);
          if (itemDif !== null) {
            dif.current[id] = itemDif.current ?? ChangeType.removed;
            dif.previous[id] = itemDif.previous ?? ChangeType.didNotExist;
          }
        }

        /// There could be items that were in the previous array that are not in the current array, so they would not have been checked above
        for (let i = 0; i < previous.length; i++) {
          const previousItem = previous[i];
          const validItemIdKey = itemIdKey !== undefined && itemIdKey in previousItem;
          const matchingItems = validItemIdKey ? current.filter(item => item[itemIdKey] === previousItem[itemIdKey]) : null;
          if (matchingItems && validItemIdKey && matchingItems.length > 1) {
            console.warn('Multiple items found with the same', itemIdKey, ":", previousItem[itemIdKey], "in", recursionKey, "at index", i, ". Skipping check for differences.", previousItem);
            // since we can't evaluate if there is a difference, we will just skip this item
            continue;
          }

          const currentItem = validItemIdKey && matchingItems ? matchingItems[0] : current[i];
          const id = validItemIdKey ? previousItem[itemIdKey] as string : i;

          if (id === undefined) {
            console.warn("id is undefined");
          }

          if (currentItem === undefined) {
            if (itemIdKey !== undefined) {
              dif.current[id] = ChangeType.removed;
              dif.previous[id] = previousItem ?? ChangeType.didNotExist;
            }
          }
        }

        if (Object.keys(dif.current).length === 0 && Object.keys(dif.previous).length === 0) {
          if (logNoDif) console.log("No dif found");
          return null;
        }
        return dif;
      } /// end of object array check

      console.warn('Not a supported array type');
      return null;
    } /// end of array check


    /// ********************** Handle Objects ********************** ///
    const dif = {
      previous: Object.create(null),
      current: Object.create(null),
    };
    for (const key in current) {
      const previousValue = previous[key];
      const currentValue = current[key];
      const itemDifItemIdKey = difAlgorithmItemIdKey[key as keyof typeof difAlgorithmItemIdKey] ?? undefined;
      const itemDif = getDif(previousValue, currentValue, itemDifItemIdKey, difAlgorithmItemIdKey, recursionDepth + 1, `${recursionKey}.${key}`, options);
      if (itemDif !== null) {
        dif.current[key] = itemDif.current ?? ChangeType.removed;
        dif.previous[key] = itemDif.previous ?? ChangeType.didNotExist;
      }
    }

    /// There could be keys in the previous object that are not in the current object, so they would not have been checked above.
    for (const key in previous) {

      if (!(key in current)) {
        if (shouldSkipKey(keysToInclude, keysToIgnore, ignoreMeta, recursionDepth + 1, key, rootKey, logChecks, false)) {
          continue;
        }
        dif.current[key] = ChangeType.removed;
        dif.previous[key] = previous[key] ?? ChangeType.didNotExist; // didNotExist should never happen
      }
    }

    if (Object.keys(dif.current).length === 0 && Object.keys(dif.previous).length === 0) {
      if (logNoDif) console.log("No dif found");
      return null;
    }

    return dif;

  } catch (error) {
    console.error("There was an error at ", recursionKey, { previous, current });
    throw error;
  }


}

function shouldSkipKey(
  keysToInclude: string[] | undefined,
  keysToIgnore: string[] | undefined,
  ignoreMeta: boolean | undefined,
  recursionDepth: number,
  recursionKeyAtCurrentDepth: string,
  rootKey: string,
  logChecks: boolean | undefined,
  isCurrent = true
) {
  if (keysToInclude && recursionDepth === 1 && !keysToInclude.includes(rootKey as keyof DifOptions['keysToInclude'])) {
    if (logChecks) console.log(recursionKeyAtCurrentDepth, `-- skipping ${isCurrent ? 'current' : 'previous'} key 🙈`);
    return true;
  }

  if (keysToIgnore && keysToIgnore.includes(recursionKeyAtCurrentDepth as keyof DifOptions['keysToIgnore'])) {
    // console.log("keysToIgnore: ", keysToIgnore);
    if (logChecks)
      console.log(recursionKeyAtCurrentDepth, `-- skipping ${isCurrent ? 'current' : 'previous'} key 🙈`);
    return true;
  }


  if (ignoreMeta && (recursionKeyAtCurrentDepth === 'lastUpdated' || recursionKeyAtCurrentDepth === 'update_time' || recursionKeyAtCurrentDepth === 'lastUpdatedBy')) {
    if (logChecks) console.log(recursionKeyAtCurrentDepth, `-- skipping ${isCurrent ? 'current' : 'previous'} metadata`);
    return true;
  }

  return false;

}


/*

getDif({
  eventName: "Jon's Event",
  members: [
    {
      "id": 1,
      "memberLook": {
        styles: [
          {
            styleCode: "A",
            styleName: "Something"
          }
        ]
      }
    },
    {
      "id": 2,
      "memberLook": {
        styles: [
          {
            styleCode: "B",
            styleName: "Something else"
          }
        ]
      }
    }
  ]
},
{
  eventName: "Jon's Event With New Name",
  members: [
    {
      "id": 1,
      "memberLook": {
        styles: [
          {
            styleCode: "A",
            styleName: "Changed Style Name"
          }, // style A was changed, style C was removed
          {
            styleCode: "C",
            styleName: "New Style"
          }
        ]
      }
    }, // member 2 was removed, member 3 was added
    {
      "id": 3,
      "memberLook": {
        styles: [
          {
            styleCode: "B",
            styleName: "Something else"
          },
        ]
      }
    }
  ]
},
{
  previous: {},
  current: {}
}
)

// current and previous are objects, so we iterate over the keys, checking each key for a dif
eventName -- checking... {
  const dif = getDif(previous[currentKey], current[currentKey]);
  if (dif !== null) {
    dif.current[currentKey] = dif.current;
    dif.previous[currentKey] = dif.previous;
  }
}
members -- checking... both are arrays, so we iterate over the arrays
  for (let i = 0; i < current.length; i++) {
    const current = current[i];
    const previous = previous[i];
    const dif = getDif(previous, current);
    if (dif !== null) {
      dif.current[i] = dif.current;
      dif.previous[i] = dif.previous;
    }
  }
  members.0 -- checking... both are objects, so we iterate over the keys
    members.0.id -- checking... both are primitive, so we set dif.current = current, dif.previous = previous
    members.0.memberLook -- checking... both are objects, so we iterate over the keys
      members.0.memberLook.styles -- checking... both are arrays, so we iterate over the arrays
        members.0.memberLook.styles.0 -- checking... both are objects, so we iterate over the keys
          members.0.memberLook.styles.0.styleCode -- checking... both are primitive, so we set dif.current = current, dif.previous = previous
          members.0.memberLook.styles.0.styleName -- checking... both are primitive, so we set dif.current = current, dif.previous = previous








*/
