import { EcomStyle, StyleType } from 'common-types';
import { isMemberLookRentalEnsemble } from '../../../ensemble/utilities/is-member-look-rental-ensemble';

export function getPayingForPrice(
  ecomStyle: EcomStyle,
  memberStyles: EcomStyle[]
): number | undefined {
  // console.log('getPayingForPrice', ecomStyle, memberStyles);

  const isEnsemble = isMemberLookRentalEnsemble(memberStyles);
  let displayedPrice = ecomStyle.price;

  const ensembleIncludedItems: StyleType[] = [
    StyleType.Pant,
    StyleType.Shirt,
    StyleType.Tie,
    StyleType.Vest,
    StyleType.Jewelry,
  ];
  const styleGroup = ecomStyle.styleGroup;

  const styleIncludedInEnsemble = ensembleIncludedItems.includes(
    ecomStyle.styleType
  );

  if (
    (ecomStyle.styleType !== 'Jewelry' ||
      ecomStyle.collection !== 'Premium (PRM)') &&
    styleIncludedInEnsemble &&
    styleGroup === 'Rental' &&
    isEnsemble
  ) {
    displayedPrice = 0;
  }

  // console.log({ isEnsemble, displayedPrice });
  return displayedPrice;
}
