import { Component, computed, effect, EventEmitter, Input, OnInit, Output, signal, SimpleChange } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
} from 'business-logic';
import { defer, distinctUntilChanged, map, of, tap } from 'rxjs';

type DateForm = {
  date: FormControl<Date | null>;
};

type DateFormGroup = FormGroup<DateForm>;

type neededType = FormGroup<{ date: FormControl<Date> }>;

// const minDateValidator = (minDate: Date): ValidatorFn => (control: AbstractControl<Date | null>): ValidationErrors | null => {
//   if(DEBUG.logAll) console.log("in minDateValidator... minDate: ", minDate);
//   const date = control.value;
//   const dif = date ? date.getTime() - minDate.getTime() : null;
//   const isValid = (minDate && dif) ? dif >= 0 : true;
//   return isValid ? null : { minDate: true };
// };

// const maxDateValidator = (maxDate: Date): ValidatorFn => (control: AbstractControl<Date | null>): ValidationErrors | null => {
//   if(DEBUG.logAll) console.log("in maxDateValidator... maxDate: ", maxDate);
//   const date = control.value;
//   const isValid = (maxDate && date) ? date.getTime() <= maxDate.getTime() : true;
//   return isValid ? null : { maxDate: true };
// };

const DEBUG = {
  logAll: true,
}

@Component({
  selector: 'app-date-form',
  templateUrl: './date-form.component.html',
  styleUrls: ['./date-form.component.scss'],
})
export class DateFormComponent implements OnInit {
  /* @param {boolean} hideHint - Dynamically hide the label text */
  @Input() hideLabel!: boolean;



  private _hideHint = signal<boolean | undefined>(undefined);
  /* @param {boolean} hideHint - Dynamically hide the hint text */
  @Input() set hideHint(hide: boolean | undefined) {
    this._hideHint.set(hide ?? false);
  }

  private _hideHintOnValid = signal<boolean | undefined>(undefined);
  /* @param {boolean} hideHintOnValid - Hide the hint text when the form is valid */
  @Input() set hideHintOnValid(val: boolean | undefined) {
    this._hideHintOnValid.set(val ?? false);
  }


  @Input() displayError = true;

  /** When Input signals become stable, we can replace this workaround. */
  errorFromInput = signal<string | undefined>(undefined);

  /** Used to override displaying min/max errors when an error is provided via the Input */
  errorSet = computed(() => !!this.errorFromInput());

  /** Use to set errors on the form field.  If an error is provided (and it is truthy),
   * only this error will be displayed.
   * If no error is provided (or it is falsy), the form field will display
   * its own validation errors for minDate, maxDate, required, and invalid format.
   * */
  @Input() set error(value: string | undefined) {
    if (DEBUG.logAll) console.log('receiving error on date form', value);
    this.errorFromInput.set(value); // eventually we can refactor this to an input signal (when those become stable)

    // if there is a value, set the error on the form field
    // if (value) {
    //   if(DEBUG.logAll) console.log('setting error on date form', value);
    //   return;
    // } else { // remove the invalid error, but keep the other errors

    //   const { invalid, ...errors } = this.form.controls.date.errors ?? {};
    //   if (Object.keys(errors).length === 0) {
    //     if(DEBUG.logAll) console.log('setting error on date form to null');
    //     this.form.controls.date.setErrors(null);
    //     // this.form.controls.date.markAsPristine();
    //   } else {
    //     if(DEBUG.logAll) console.log('setting error on date form to previous errors', errors);
    //     this.form.controls.date.setErrors(errors);
    //   }
    // }

  }


  @Input() required = true;

  _minDate = signal<Date | undefined>(undefined);

  @Input() set minDate(date: Date | undefined) {
    console.log('minDate', date);
    const dateValue = this.form.controls.date.value;
    if (date && dateValue && dateValue < date && !this.errorSet()) {
      console.log('setting minDate error');
      this.form.controls.date.setErrors({ ...this.form.controls.date.errors, matDatepickerMin: true })
      this.form.controls.date.markAsTouched();
    } else if (date && dateValue && dateValue >= date) {
      console.log('removing minDate error');
      const { matDatepickerMin, ...errors } = this.form.controls.date.errors ?? {};
      if (Object.keys(errors).length === 0) {
        this.form.controls.date.setErrors(null);
      } else {
        this.form.controls.date.setErrors(errors);
      }
    }
    this._minDate.set(date);

  }

  _maxDate = signal<Date | undefined>(undefined);

  @Input() set maxDate(date: Date | undefined) {
    console.log('maxDate', date);
    const dateValue = this.form.controls.date.value;
    if (date && dateValue && dateValue > date && !this.errorSet()) {
      console.log('setting maxDate error');
      this.form.controls.date.setErrors({ ...this.form.controls.date.errors, matDatepickerMax: true });
      this.form.controls.date.markAsTouched();
    } else if (date && dateValue && dateValue <= date) {
      console.log('removing maxDate error');
      const { matDatepickerMax, ...errors } = this.form.controls.date.errors ?? {};
      if (Object.keys(errors).length === 0) {
        this.form.controls.date.setErrors(null);
      } else {
        this.form.controls.date.setErrors(errors);
      }
    }
    this._maxDate.set(date);
  }
  @Input() label = '';
  @Input() hideRequiredMarker = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  private _textAlign = signal<string | undefined>(undefined);
  @Input() set textAlign(val: string | undefined) {
    this._textAlign.set(val);
  }

  private _fontWeight = signal<string | undefined>(undefined);
  @Input() set fontWeight(val: string | undefined) {
    this._fontWeight.set(val);
  }

  formFieldClass = computed(() => {
    const textAlign = this._textAlign();
    const fontWeight = this._fontWeight();
    const classes = [];
    if (textAlign) {
      classes.push(textAlign);
    }
    if (fontWeight) {
      classes.push(fontWeight);
    }
    return classes.join(' ');
  });

  private _disabled = signal<boolean | { disabled: boolean, emitEvent: boolean }>(false);

  /** Will disable the field */
  @Input() set disabled(disabled: boolean | { disabled: boolean, emitEvent: boolean }) {
    if (DEBUG.logAll) console.log("disabled input", disabled);
    this._disabled.set(disabled);
  }

  /** Handles enabling and disabling the form when this._disabled signal changes */
  disableForm = effect(() => {
    const disabled = this._disabled();
    if (DEBUG.logAll) console.log('disableForm', disabled);
    if (typeof disabled === 'object') {
      const { disabled: isDisabled, emitEvent } = disabled;
      if (isDisabled) this.form.disable({ emitEvent });
      else this.form.enable({ emitEvent });
    } else {
      if (disabled) this.form.disable({ emitEvent: false });
      else this.form.enable({ emitEvent: false });
    }
  });

  /** Controls which event the form updates on.
   * Default is 'change' which is the default for Angular forms.
   * Possible values are 'change', 'blur', 'submit'
     */
  @Input() updateOn: FormControl['updateOn'] = 'change';

  /** Set the initial value of the form field, unless it is 0, in which case the form is not patched */
  @Input()
  set initialValue(value: number) {
    if (DEBUG.logAll) console.log('initialValue', value);
    if (value !== 0) {
      const date = convertUnixTimestampToDate(value);
      this.form.patchValue(
        { date: date },
        { emitEvent: false }
      );
    }
  }


  form = new FormGroup<DateForm>({
    date: new FormControl<Date | null>(null, { updateOn: this.updateOn }),
  });

  dateValueChanges = toSignal(this.form.controls.date.valueChanges);

  /** Only show minDate error if there is no error set by the Input error */
  showMinDateError = computed(() => {
    this._minDate();
    this.dateValueChanges();
    const showMinDateError = !this.errorSet() && this.form.controls.date.hasError('matDatepickerMin');
    if (DEBUG.logAll) console.log('minDateError date form', this.form.controls.date);
    return showMinDateError;
  });

  /** Only show maxDate error if there is no error set by the Input error */
  showMaxDateError = computed(() => {
    this._maxDate();
    this.dateValueChanges();
    return !this.errorSet() && this.form.controls.date.hasError('matDatepickerMax');
  });

  /** Only show required error if there is no error set by the Input error
   * but don't show if there is a format error
   */
  showRequiredError = computed(() => {
    this.dateValueChanges();
    return !this.errorSet() &&
      this.form.controls.date.hasError('required')
      && !this.form.controls.date.hasError('matDatepickerParse'); // Don't show required error if the date is invalid
  });

  /** Always show a format error */
  showInvalidFormatError = computed(() => {
    this.dateValueChanges();
    return this.form.controls.date.hasError('matDatepickerParse') // always show format error, even with custom error
  });

  shouldHideHint = computed(() => {
    this.dateValueChanges();
    const disabled = this._disabled();
    return (
      this._hideHint() ||
      (this._hideHintOnValid() &&
        this.form.controls.date.valid &&
        !!this.form.controls.date.value) ||
      disabled // looks cleaner to not see hint if form is disabled
    );
  });

  updateErrorsOnInput = effect(() => {
    if (DEBUG.logAll) console.log('updateErrorsOnInput');
    /** manually add/remove the errorFromInput to the form */
    const errorFromInput = this.errorFromInput();
    if (errorFromInput) {
      if (DEBUG.logAll) console.log('setting error on date form', errorFromInput);
      setTimeout(() => {
        this.form.controls.date.setErrors({ ...this.form.controls.date.errors, invalid: true });
        this.form.controls.date.markAsTouched();
      });
      return;
    } else { // remove the invalid error, but keep the other errors

      const { invalid, ...errors } = this.form.controls.date.errors ?? {};
      if (Object.keys(errors).length === 0) {
        if (DEBUG.logAll) console.log('setting error on date form to null');
        setTimeout(() => {
          this.form.controls.date.setErrors(null);
        });
      } else {
        if (DEBUG.logAll) console.log('setting error on date form to previous errors', errors);
        setTimeout(() => {
          this.form.controls.date.setErrors(errors);
        });
      }
    }
  });


  @Output() formReady = of(this.form);

  @Output()
  valueChange = defer(() => {
    return this.form.controls.date.valueChanges.pipe(
      tap((value) => {
        if (DEBUG.logAll) console.log('valueChange in DateComponent', value);
      }),
    // startWith(this.form.value),
      distinctUntilChanged((prev, cur) => {
        const isSame = prev === cur
        if (DEBUG.logAll) console.log('distinctUntilChanged isSame:', isSame);
        return isSame;
      }),
      tap((value) => {
        // value changes will remove the errors from the form field,
        // but if there is an error from the Input, we need to re-add it
        const errorFromInput = this.errorFromInput();
        if (errorFromInput) {
          this.form.controls.date.setErrors({ ...this.form.controls.date.errors, invalid: true });
        }
      }),
      map((formValue) => {
        const valueToSend = formValue
          ? convertDateToUnixTimestamp(formValue.toDateString())
          : undefined;
        if (DEBUG.logAll) console.log('valueChange being sent at end of pipe', valueToSend);
        return valueToSend;
      })
    );
  });

  /** Emits on date input field blur and on date picker closed */
  @Output() public onBlur: EventEmitter<void> = new EventEmitter();

  /** Manually set the date on the component.
   * Not intended for setting the initialValue.  Use the initialValue Input for that.
   * @param date - The date to set
   * @param emitEvent - Whether to emit the valueChanges event (default is false).
   * Beware of infinite loops if set to true and the value is being set from the valueChanges event.
   */
  public setDate(date: Date | null, emitEvent: boolean = false): void {
    this.form.controls.date.setValue(date, { emitEvent });
  }

  ngOnInit(): void {
    // if (this.textAlign) {
    //   this.formFieldClasses.update(formFieldClasses => ([...formFieldClasses, this.textAlign]));
    // }
    // if (this.fontWeight) {
    //   this.formFieldClasses.update(formFieldClasses => ([...formFieldClasses, this.fontWeight]));
    // }
    // /*
    //   the minDate property on Mat DatePicker only controls
    //   which dates are displayed and therefore selectable,
    //   but does not handle if a date is manually entered in the form field,
    //   which could be before the minDate. So this adds a validator to the form
    //   to check if the date is before the minDate, and if so, returns the "minDate" error.
    //   Only adds the validator if the minDate property is set.
    // */
    // if(this.minDate) {
    //   if(DEBUG.logAll) console.log("adding minDateValidator to date form");
    //   this.form.controls.date.addValidators(minDateValidator(this.minDate))
    //   this.form.controls.date.updateValueAndValidity();
    // };

    // // same as above, but for optional maxDate property
    // if(this.maxDate) {
    //   this.form.controls.date.addValidators(maxDateValidator(this.maxDate));
    //   this.form.controls.date.updateValueAndValidity();
    // }
  }

  ngOnChanges(change: SimpleChange): void {
    if (DEBUG.logAll) console.log('ngOnChanges', change);





  // const dateValue = this.form.controls.date.value;
  // if (this.maxDate && dateValue && dateValue > this.maxDate) {
  //   setTimeout(() => {
  //     this.form.controls.date.setErrors({ matDatepickerMax: true });
  //   });
  // }
    // if (this.errorFromInput()) {
    //   setTimeout(() => {
    //     this.form.controls.date.setErrors({ invalid: true });
    //   });

    //   this.form.controls.date.markAsTouched();
    // } else {
    //   if(DEBUG.logAll) console.log('no error');
    //   this.form.controls.date.setErrors(null);
    //   this.form.controls.date.markAsPristine();
    // }
  }

  // formFieldClass(): string {
  //   return this.formFieldClasses.join(' ');
  // }
}
