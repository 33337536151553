import {
  CanBeValidated,
  EventMember as EventMemberInterface,
  Look,
  Measurements,
  Serializable,
  ShippingAddress,
} from 'common-types';
import { ZodError, ZodSchema } from 'zod';
import { generateUUID } from '../../common';
import { IdAlreadyExistsError } from '../../common/errors/id-already-exists.error';
import { EventMemberValidator } from './validation/event-member-validator';

export class EventMember
  implements
    Partial<EventMemberInterface>,
    CanBeValidated,
    Serializable<EventMember>
{
  id?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  memberRole?: string | undefined;
  memberLook?: Look | undefined;
  phone?: string | undefined;
  email?: string | undefined;
  shippingAddress?: ShippingAddress | undefined;
  measurements?: Measurements | undefined;
  surrogateUser?: boolean | undefined;
  acceptsTexts?: boolean | undefined;
  userPaying?: string | undefined;

  toObject() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      memberRole: this.memberRole,
      memberLook: this.memberLook,
      phone: this.phone,
      email: this.email,
      shippingAddress: this.shippingAddress,
      measurements: this.measurements,
      surrogateUser: this.surrogateUser,
      acceptsTexts: this.acceptsTexts,
      userPaying: this.userPaying,
    };
  }

  serialize(): string {
    return JSON.stringify(this.toObject());
  }

  deserialize(input: Partial<EventMemberInterface>): EventMember {
    this.id = input.id;
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.memberRole = input.memberRole;
    this.memberLook = input.memberLook;
    this.phone = input.phone;
    this.email = input.email;
    this.shippingAddress = input.shippingAddress;
    this.measurements = input.measurements;
    this.surrogateUser = input.surrogateUser;
    this.acceptsTexts = input.acceptsTexts;
    this.userPaying = input.id;
    return this;
  }

  isValid(validationSchema?: ZodSchema): boolean {
    if (validationSchema === undefined) {
      validationSchema = EventMemberValidator;
    }

    try {
      validationSchema.parse(this);
      return true;
    } catch (err) {
      if (err instanceof ZodError) {
        return false;
      } else {
        throw err;
      }
    }
  }

  getErrors(validationSchema?: ZodSchema): ZodError<any> | undefined {
    if (validationSchema === undefined) {
      validationSchema = EventMemberValidator;
    }

    try {
      validationSchema.parse(this);
    } catch (err) {
      if (err instanceof ZodError) {
        return err;
      } else {
        throw err;
      }
    }

    return undefined;
  }

  generateId(): void | IdAlreadyExistsError {
    if (this.id === undefined) {
      this.id = generateUUID(true); // window.crypto.generateUUID()
    } else {
      throw new IdAlreadyExistsError(
        'Member id was already set, but generateId() was called.',
      );
    }
  }
}
