import { convertDateToUnixTimestamp } from '../../date/utilities';
import { getMaxDateForEvent } from './get-max-event-date';
import { getOnlineLeadTimeDays } from './get-online-lead-time-days';


/**
 * Checks if an event can be created online based on the event date (which should be in Unix epoch timestamp, in seconds).
 * The event date must be at least {@link getOnlineLeadTimeDays} in the future AND not more than {@link getMaxDateForEvent} in the future (from today's date).
 * @param eventDate The date of the event in unix timestamp
 * @returns
 */
export function canEventBeCreatedOnline(eventDate: number): boolean {

  const minDate: Date = new Date();
  minDate.setHours(0, 0, 0, 0);
  minDate.setDate(minDate.getDate() + getOnlineLeadTimeDays());
  const minUnixTimestamp = convertDateToUnixTimestamp(minDate.toDateString());
  const isEventTooSoon = eventDate < minUnixTimestamp;
  if (isEventTooSoon) {
    // console.log('Event is too soon', { eventDate, minUnixTimestamp });
    return false;
  }

  const maxDate = getMaxDateForEvent();
  const maxDateUnixTimestamp = convertDateToUnixTimestamp(maxDate.toDateString());
  const isEventTooFar = eventDate > maxDateUnixTimestamp;
  if (isEventTooFar) {
    // console.log('Event is too far in the future', { eventDate, maxDateUnixTimestamp });
    return false;
  }

  return true;


  /// Original code, did not consider max date or dates in the past
  // const minOnlineDate = new Date();
  // minOnlineDate.setHours(0, 0, 0, 0);
  // minOnlineDate.setDate(minOnlineDate.getDate() + getOnlineLeadTimeDays());

  // const minOnlineUnixTimestamp = convertDateToUnixTimestamp(
  //   minOnlineDate.toDateString()
  // );

  // if (eventDate < minOnlineUnixTimestamp) {
  //   return false;
  // } else {
  //   return true;
  // }

}
