import { SupportedArray, SupportedObject, SupportedPrimitive, SupportedPrimitivesWithoutNullArray, SupportedTypes as SupportedType } from "../types";

export function isSupportedType(value: SupportedType): value is SupportedType {
  if (isSupportedPrimitive(value)) return true;
  if (isSupportedArray(value)) return true;
  if (isSupportedObject(value)) return true;
  return false;
}

/** The value is a supported primitive, including null or undefined.  */
export function isSupportedPrimitive(value: SupportedType): value is SupportedPrimitive {
  return SupportedPrimitivesWithoutNullArray.includes(typeof value as typeof SupportedPrimitivesWithoutNullArray[number])
    || value === null
    || value === undefined;
}

/** The value is a supported object and is not null or undefined.  */
export function isSupportedObject(value: SupportedType): value is SupportedObject {
  return typeof value === 'object'
    && !Array.isArray(value)
    && value !== null &&
    Object.keys(value).every((key) => isSupportedType(value[key]))
    && value !== undefined;
}
/** The value is a supported array and is not null or undefined.  */
export function isSupportedArray(value: SupportedType): value is SupportedArray {
  return Array.isArray(value) && value.every((item) => isSupportedType(item))
    && value !== null
    && value !== undefined;
}

/** The value is a supported array of primitives and is not null or undefined.  */
export function isSupportedSimpleArray(value: SupportedType): value is Array<SupportedPrimitive> {
  return Array.isArray(value) && value.every((item) => isSupportedPrimitive(item))
    && value !== null
    && value !== undefined;
}

export function isSupportedObjectArray(value: SupportedType): value is Array<SupportedObject> {
  return Array.isArray(value) && value.every((item) => isSupportedObject(item))
    && value !== null
    && value !== undefined;
}
