import { EventMember } from 'common-types';

export function eventMemberMeasurementsComplete(member: EventMember): boolean {
  // EXT-2317 Temporary change to by pass tool.
  if (
    member.measurements?.derivedFrom === 'tool' &&
    member.measurements.submitted === true
  ) {
    return true;
  }

  if (member.internalOrderNumber !== undefined && member.internalOrderNumber !== 0) {
    return true;
  }
  // EXT-2317

  if (member.measurements === undefined) {
    return false;
  }

  if (member.memberLook === undefined) {
    return false;
  }

  if (
    member.measurements.chest === undefined ||
    member.measurements.overarm === undefined ||
    member.measurements.waist === undefined ||
    member.measurements.outseam === undefined ||
    member.measurements.neck === undefined ||
    member.measurements.sleeve === undefined ||
    member.measurements.height === undefined ||
    member.measurements.weight === undefined ||
    member.measurements.hip === undefined
  ) {
    // all required measurements.
    return false;
  }

  const memberHasShoes =
    member.memberLook.styles.findIndex((style) => {
      return style.styleType === 'Shoes';
    }) < 0
      ? false
      : true;

  // if they have a shoe, make sure there's a shoe size.
  if (memberHasShoes && member.measurements.shoeSize === undefined) {
    return false;
  }

  if (member.measurements?.submitted) {
    return true;
  }

  /// Adding the original derivedFrom=complete check for legacy events
  if (member.measurements?.derivedFrom === 'complete') {
    return true;
  }

  return false;
}
