import { Event } from 'common-types';
import { EventItemIdKeys } from './constants';
import { Change, Dif } from './types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';
// type EventChange = {
//   previous: Partial<Event>,
//   current: Partial<Event>,
// }


export type EventChange = Change<Event, [EventItemIdKeys]>;
export type EventDif = Dif<EventChange>;


export function getEventDif(previous: Event | undefined, current: Event | undefined, options: DifOptions = DEFAULT_DIF_OPTIONS): EventDif {

  return getDifByKeyFromEvent(previous, current, 'event', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const event: Event = {} as Event;

// getEventDif(event, event, { keysToIgnore: ['name'], keysToInclude: ['looks'] });



