import { Event, EventAdmin } from 'common-types';
import { AdminsDif } from './types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';

export function getAdminsDif(previous: EventAdmin[] | undefined, current: EventAdmin[] | undefined, options?: DifOptions<'admins'>): AdminsDif {

  const currentEvent: Event = {
    admins: current
  } as Event;

  const previousEvent: Event = {
    admins: previous
  } as Event;

  return getDifByKeyFromEvent(previousEvent, currentEvent, 'admins', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);
  // return getDif(previous, current, EVENT_ITEM_ID_KEYS['admins'], EVENT_ITEM_ID_KEYS, 0, 'admins', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const admins = [] as EventAdmin[];

// getAdminsDif(admins, admins, { keysToIgnore: ['inviteSent'] });
