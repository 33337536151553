import { EcomStyle } from 'common-types';

export const shouldModifyElasticStyle = (style: EcomStyle): boolean => {
  if (style.styleGroup === 'Rental') {
    return true;
  }
  if (style.styleGroup === 'Purchase' && !style.companionStyleCode) {
    return true;
  }
  return false;
};
