type Coat = {
  coatSize: string;
  legacyCoatSize: string;
  legacyCoatLength: string;
};
const coatSizeRange: Array<Coat> = [
  { coatSize: '3 Boys', legacyCoatSize: '03', legacyCoatLength: 'B' },
  { coatSize: '4 Boys', legacyCoatSize: '04', legacyCoatLength: 'B' },
  { coatSize: '5 Boys', legacyCoatSize: '05', legacyCoatLength: 'B' },
  { coatSize: '6 Boys', legacyCoatSize: '06', legacyCoatLength: 'B' },
  { coatSize: '7 Boys', legacyCoatSize: '07', legacyCoatLength: 'B' },
  { coatSize: '8 Boys', legacyCoatSize: '08', legacyCoatLength: 'B' },
  { coatSize: '10 Boys', legacyCoatSize: '10', legacyCoatLength: 'B' },
  { coatSize: '12 Boys', legacyCoatSize: '12', legacyCoatLength: 'B' },
  { coatSize: '14 Boys', legacyCoatSize: '14', legacyCoatLength: 'B' },
  { coatSize: '16 Boys', legacyCoatSize: '16', legacyCoatLength: 'B' },
  { coatSize: '18 Boys', legacyCoatSize: '18', legacyCoatLength: 'B' },
  { coatSize: '34 Regular', legacyCoatSize: '34', legacyCoatLength: 'R' },
  { coatSize: '34 Short', legacyCoatSize: '34', legacyCoatLength: 'S' },
  { coatSize: '34 Long', legacyCoatSize: '34', legacyCoatLength: 'L' },
  { coatSize: '35 Regular', legacyCoatSize: '35', legacyCoatLength: 'R' },
  { coatSize: '35 Short', legacyCoatSize: '35', legacyCoatLength: 'S' },
  { coatSize: '35 Long', legacyCoatSize: '35', legacyCoatLength: 'L' },
  { coatSize: '36 Regular', legacyCoatSize: '36', legacyCoatLength: 'R' },
  { coatSize: '36 Short', legacyCoatSize: '36', legacyCoatLength: 'S' },
  { coatSize: '36 Long', legacyCoatSize: '36', legacyCoatLength: 'L' },
  { coatSize: '37 Regular', legacyCoatSize: '37', legacyCoatLength: 'R' },
  { coatSize: '37 Short', legacyCoatSize: '37', legacyCoatLength: 'S' },
  { coatSize: '37 Long', legacyCoatSize: '37', legacyCoatLength: 'L' },
  { coatSize: '38 Regular', legacyCoatSize: '38', legacyCoatLength: 'R' },
  { coatSize: '38 Short', legacyCoatSize: '38', legacyCoatLength: 'S' },
  { coatSize: '38 Long', legacyCoatSize: '38', legacyCoatLength: 'L' },
  { coatSize: '38 Extra Long', legacyCoatSize: '38', legacyCoatLength: 'X' },
  { coatSize: '39 Regular', legacyCoatSize: '39', legacyCoatLength: 'R' },
  { coatSize: '39 Short', legacyCoatSize: '39', legacyCoatLength: 'S' },
  { coatSize: '39 Long', legacyCoatSize: '39', legacyCoatLength: 'L' },
  { coatSize: '39 Extra Long', legacyCoatSize: '39', legacyCoatLength: 'X' },
  { coatSize: '40 Regular', legacyCoatSize: '40', legacyCoatLength: 'R' },
  { coatSize: '40 Short', legacyCoatSize: '40', legacyCoatLength: 'S' },
  { coatSize: '40 Long', legacyCoatSize: '40', legacyCoatLength: 'L' },
  { coatSize: '40 Extra Long', legacyCoatSize: '40', legacyCoatLength: 'X' },
  { coatSize: '41 Regular', legacyCoatSize: '41', legacyCoatLength: 'R' },
  { coatSize: '41 Short', legacyCoatSize: '41', legacyCoatLength: 'S' },
  { coatSize: '41 Long', legacyCoatSize: '41', legacyCoatLength: 'L' },
  { coatSize: '41 Extra Long', legacyCoatSize: '41', legacyCoatLength: 'X' },
  { coatSize: '42 Regular', legacyCoatSize: '42', legacyCoatLength: 'R' },
  { coatSize: '42 Short', legacyCoatSize: '42', legacyCoatLength: 'S' },
  { coatSize: '42 Long', legacyCoatSize: '42', legacyCoatLength: 'L' },
  { coatSize: '42 Extra Long', legacyCoatSize: '42', legacyCoatLength: 'X' },
  { coatSize: '43 Regular', legacyCoatSize: '43', legacyCoatLength: 'R' },
  { coatSize: '43 Short', legacyCoatSize: '43', legacyCoatLength: 'S' },
  { coatSize: '43 Long', legacyCoatSize: '43', legacyCoatLength: 'L' },
  { coatSize: '43 Extra Long', legacyCoatSize: '43', legacyCoatLength: 'X' },
  { coatSize: '44 Regular', legacyCoatSize: '44', legacyCoatLength: 'R' },
  { coatSize: '44 Short', legacyCoatSize: '44', legacyCoatLength: 'S' },
  { coatSize: '44 Long', legacyCoatSize: '44', legacyCoatLength: 'L' },
  { coatSize: '44 Extra Long', legacyCoatSize: '44', legacyCoatLength: 'X' },
  { coatSize: '45 Regular', legacyCoatSize: '45', legacyCoatLength: 'R' },
  { coatSize: '45 Short', legacyCoatSize: '45', legacyCoatLength: 'S' },
  { coatSize: '45 Long', legacyCoatSize: '45', legacyCoatLength: 'L' },
  { coatSize: '45 Extra Long', legacyCoatSize: '45', legacyCoatLength: 'X' },
  { coatSize: '46 Regular', legacyCoatSize: '46', legacyCoatLength: 'R' },
  { coatSize: '46 Short', legacyCoatSize: '46', legacyCoatLength: 'S' },
  { coatSize: '46 Long', legacyCoatSize: '46', legacyCoatLength: 'L' },
  { coatSize: '46 Extra Long', legacyCoatSize: '46', legacyCoatLength: 'X' },
  { coatSize: '47 Regular', legacyCoatSize: '47', legacyCoatLength: 'R' },
  { coatSize: '47 Short', legacyCoatSize: '47', legacyCoatLength: 'S' },
  { coatSize: '47 Long', legacyCoatSize: '47', legacyCoatLength: 'L' },
  { coatSize: '48 Regular', legacyCoatSize: '48', legacyCoatLength: 'R' },
  { coatSize: '48 Short', legacyCoatSize: '48', legacyCoatLength: 'S' },
  { coatSize: '48 Long', legacyCoatSize: '48', legacyCoatLength: 'L' },
  { coatSize: '48 Extra Long', legacyCoatSize: '48', legacyCoatLength: 'X' },
  { coatSize: '50 Regular', legacyCoatSize: '50', legacyCoatLength: 'R' },
  { coatSize: '50 Short', legacyCoatSize: '50', legacyCoatLength: 'S' },
  { coatSize: '50 Long', legacyCoatSize: '50', legacyCoatLength: 'L' },
  { coatSize: '50 Extra Long', legacyCoatSize: '50', legacyCoatLength: 'X' },
  { coatSize: '52 Regular', legacyCoatSize: '52', legacyCoatLength: 'R' },
  { coatSize: '52 Short', legacyCoatSize: '52', legacyCoatLength: 'S' },
  { coatSize: '52 Long', legacyCoatSize: '52', legacyCoatLength: 'L' },
  { coatSize: '52 Extra Long', legacyCoatSize: '52', legacyCoatLength: 'X' },
  { coatSize: '54 Regular', legacyCoatSize: '54', legacyCoatLength: 'R' },
  { coatSize: '54 Short', legacyCoatSize: '54', legacyCoatLength: 'S' },
  { coatSize: '54 Long', legacyCoatSize: '54', legacyCoatLength: 'L' },
  { coatSize: '54 Extra Long', legacyCoatSize: '54', legacyCoatLength: 'X' },
  { coatSize: '56 Regular', legacyCoatSize: '56', legacyCoatLength: 'R' },
  { coatSize: '56 Short', legacyCoatSize: '56', legacyCoatLength: 'S' },
  { coatSize: '56 Long', legacyCoatSize: '56', legacyCoatLength: 'L' },
  { coatSize: '56 Extra Long', legacyCoatSize: '56', legacyCoatLength: 'X' },
  { coatSize: '58 Regular', legacyCoatSize: '58', legacyCoatLength: 'R' },
  { coatSize: '58 Short', legacyCoatSize: '58', legacyCoatLength: 'S' },
  { coatSize: '58 Long', legacyCoatSize: '58', legacyCoatLength: 'L' },
  { coatSize: '58 Extra Long', legacyCoatSize: '58', legacyCoatLength: 'X' },
  { coatSize: '60 Regular', legacyCoatSize: '60', legacyCoatLength: 'R' },
  { coatSize: '60 Short', legacyCoatSize: '60', legacyCoatLength: 'S' },
  { coatSize: '60 Long', legacyCoatSize: '60', legacyCoatLength: 'L' },
  { coatSize: '60 Extra Long', legacyCoatSize: '60', legacyCoatLength: 'X' },
  { coatSize: '62 Regular', legacyCoatSize: '62', legacyCoatLength: 'R' },
  { coatSize: '62 Long', legacyCoatSize: '62', legacyCoatLength: 'L' },
  { coatSize: '62 Extra Long', legacyCoatSize: '62', legacyCoatLength: 'X' },
  { coatSize: '64 Regular', legacyCoatSize: '64', legacyCoatLength: 'R' },
  { coatSize: '64 Long', legacyCoatSize: '64', legacyCoatLength: 'L' },
  { coatSize: '64 Extra Long', legacyCoatSize: '64', legacyCoatLength: 'X' },
  { coatSize: '66 Regular', legacyCoatSize: '66', legacyCoatLength: 'R' },
  { coatSize: '66 Long', legacyCoatSize: '66', legacyCoatLength: 'L' },
  { coatSize: '66 Extra Long', legacyCoatSize: '66', legacyCoatLength: 'X' },
  { coatSize: '68 Regular', legacyCoatSize: '68', legacyCoatLength: 'R' },
  { coatSize: '68 Long', legacyCoatSize: '68', legacyCoatLength: 'L' },
  { coatSize: '70 Regular', legacyCoatSize: '70', legacyCoatLength: 'R' },
  { coatSize: '70 Long', legacyCoatSize: '70', legacyCoatLength: 'L' },
  { coatSize: '72 Regular', legacyCoatSize: '72', legacyCoatLength: 'R' },
  { coatSize: '72 Long', legacyCoatSize: '72', legacyCoatLength: 'L' },
  { coatSize: '74 Regular', legacyCoatSize: '74', legacyCoatLength: 'R' },
  { coatSize: '74 Long', legacyCoatSize: '74', legacyCoatLength: 'L' },
];

export function getLegacyCoatSize(coatSize: string): [string, string] {
  let coat: Coat | undefined = coatSizeRange.find((coat: Coat) => {
    return coatSize === coat.coatSize;
  });
  if (coat) {
    return [coat.legacyCoatSize, coat.legacyCoatLength];
  } else {
    return ['', ''];
  }
}

export function getCoatSize(
  legacyCoatSize: string,
  legacyCoatLength: string,
): string | undefined {
  let coat: Array<Coat> = coatSizeRange.filter((coat: Coat) => {
    return (
      legacyCoatSize.trim() === coat.legacyCoatSize &&
      legacyCoatLength.trim() === coat.legacyCoatLength
    );
  });
  if (coat[0] !== undefined) {
    return coat[0].coatSize;
  } else {
    return undefined;
  }
}
