type Shoe = {
  shoeSize: string;
  legacyShoeSize: string;
  legacyShoeWidth: string;
};

const shoeSizeRange: Array<Shoe> = [
  { shoeSize: '6', legacyShoeSize: '060', legacyShoeWidth: 'M' },
  { shoeSize: '6 1/2', legacyShoeSize: '065', legacyShoeWidth: 'M' },
  { shoeSize: '6 1/2 Wide', legacyShoeSize: '065', legacyShoeWidth: 'W' },
  { shoeSize: '7', legacyShoeSize: '070', legacyShoeWidth: 'M' },
  { shoeSize: '7 Wide', legacyShoeSize: '070', legacyShoeWidth: 'W' },
  { shoeSize: '7 1/2', legacyShoeSize: '075', legacyShoeWidth: 'M' },
  { shoeSize: '7 1/2 Wide', legacyShoeSize: '075', legacyShoeWidth: 'W' },
  { shoeSize: '8', legacyShoeSize: '080', legacyShoeWidth: 'M' },
  { shoeSize: '8 Wide', legacyShoeSize: '080', legacyShoeWidth: 'W' },
  { shoeSize: '8 1/2', legacyShoeSize: '085', legacyShoeWidth: 'M' },
  { shoeSize: '8 1/2 Wide', legacyShoeSize: '085', legacyShoeWidth: 'W' },
  { shoeSize: '9', legacyShoeSize: '090', legacyShoeWidth: 'M' },
  { shoeSize: '9 Wide', legacyShoeSize: '090', legacyShoeWidth: 'W' },
  { shoeSize: '9 1/2', legacyShoeSize: '095', legacyShoeWidth: 'M' },
  { shoeSize: '9 1/2 Wide', legacyShoeSize: '095', legacyShoeWidth: 'W' },
  { shoeSize: '10', legacyShoeSize: '100', legacyShoeWidth: 'M' },
  { shoeSize: '10 Wide', legacyShoeSize: '100', legacyShoeWidth: 'W' },
  { shoeSize: '10 1/2', legacyShoeSize: '105', legacyShoeWidth: 'M' },
  { shoeSize: '10 1/2 Wide', legacyShoeSize: '105', legacyShoeWidth: 'W' },
  { shoeSize: '11', legacyShoeSize: '110', legacyShoeWidth: 'M' },
  { shoeSize: '11 Wide', legacyShoeSize: '110', legacyShoeWidth: 'W' },
  { shoeSize: '11 1/2', legacyShoeSize: '115', legacyShoeWidth: 'M' },
  { shoeSize: '11 1/2 Wide', legacyShoeSize: '115', legacyShoeWidth: 'W' },
  { shoeSize: '12', legacyShoeSize: '120', legacyShoeWidth: 'M' },
  { shoeSize: '12 Wide', legacyShoeSize: '120', legacyShoeWidth: 'W' },
  { shoeSize: '13', legacyShoeSize: '130', legacyShoeWidth: 'M' },
  { shoeSize: '13 Wide', legacyShoeSize: '130', legacyShoeWidth: 'W' },
  { shoeSize: '14', legacyShoeSize: '140', legacyShoeWidth: 'M' },
  { shoeSize: '14 Wide', legacyShoeSize: '140', legacyShoeWidth: 'W' },
  { shoeSize: '15', legacyShoeSize: '150', legacyShoeWidth: 'M' },
  { shoeSize: '15 Wide', legacyShoeSize: '150', legacyShoeWidth: 'W' },
  { shoeSize: '16', legacyShoeSize: '160', legacyShoeWidth: 'M' },
  { shoeSize: '16 Wide', legacyShoeSize: '160', legacyShoeWidth: 'W' },
  { shoeSize: '17', legacyShoeSize: '170', legacyShoeWidth: 'M' },
  { shoeSize: '17 Wide', legacyShoeSize: '170', legacyShoeWidth: 'W' },
  { shoeSize: '18', legacyShoeSize: '180', legacyShoeWidth: 'M' },
  { shoeSize: '18 Wide', legacyShoeSize: '180', legacyShoeWidth: 'W' },
  { shoeSize: '7 Toddler', legacyShoeSize: '070', legacyShoeWidth: 'B' },
  { shoeSize: '7 1/2 Toddler', legacyShoeSize: '075', legacyShoeWidth: 'B' },
  { shoeSize: '8 Toddler', legacyShoeSize: '080', legacyShoeWidth: 'B' },
  { shoeSize: '8 1/2 Toddler', legacyShoeSize: '085', legacyShoeWidth: 'B' },
  { shoeSize: '9 Toddler', legacyShoeSize: '090', legacyShoeWidth: 'B' },
  { shoeSize: '9 1/2 Toddler', legacyShoeSize: '095', legacyShoeWidth: 'B' },
  { shoeSize: '10 Toddler', legacyShoeSize: '100', legacyShoeWidth: 'B' },
  { shoeSize: '10 1/2 Toddler', legacyShoeSize: '105', legacyShoeWidth: 'B' },
  { shoeSize: '11 Toddler', legacyShoeSize: '110', legacyShoeWidth: 'B' },
  { shoeSize: '11 1/2 Toddler', legacyShoeSize: '115', legacyShoeWidth: 'B' },
  { shoeSize: '12 Toddler', legacyShoeSize: '120', legacyShoeWidth: 'B' },
  { shoeSize: '12 1/2 Toddler', legacyShoeSize: '125', legacyShoeWidth: 'B' },
  { shoeSize: '13 Toddler', legacyShoeSize: '130', legacyShoeWidth: 'B' },
  { shoeSize: '13 1/2 Toddler', legacyShoeSize: '135', legacyShoeWidth: 'B' },
  { shoeSize: '1 Boys', legacyShoeSize: '010', legacyShoeWidth: 'B' },
  { shoeSize: '1 1/2 Boys', legacyShoeSize: '015', legacyShoeWidth: 'B' },
  { shoeSize: '2 Boys', legacyShoeSize: '020', legacyShoeWidth: 'B' },
  { shoeSize: '2 1/2 Boys', legacyShoeSize: '025', legacyShoeWidth: 'B' },
  { shoeSize: '3 Boys', legacyShoeSize: '030', legacyShoeWidth: 'B' },
  { shoeSize: '3 1/2 Boys', legacyShoeSize: '035', legacyShoeWidth: 'B' },
  { shoeSize: '4 Boys', legacyShoeSize: '040', legacyShoeWidth: 'B' },
  { shoeSize: '4 1/2 Boys', legacyShoeSize: '045', legacyShoeWidth: 'B' },
  { shoeSize: '5 Boys', legacyShoeSize: '050', legacyShoeWidth: 'B' },
  { shoeSize: '5 1/2 Boys', legacyShoeSize: '055', legacyShoeWidth: 'B' },
];

export function getLegacyShoeSize(shoeSize: string): [string, string] {
  let shoe: Shoe | undefined = shoeSizeRange.find((shoe: Shoe) => {
    return shoeSize === shoe.shoeSize;
  });
  if (shoe) {
    return [shoe.legacyShoeSize, shoe.legacyShoeWidth];
  } else {
    return ['', ''];
  }
}

export function getShoeSize(
  legacyShoeSize: string,
  legacyShoeWidth: string,
): string | undefined {
  let shoe: Array<Shoe> = shoeSizeRange.filter((shoe: Shoe) => {
    return (
      legacyShoeSize.trim() === shoe.legacyShoeSize &&
      legacyShoeWidth.trim() === shoe.legacyShoeWidth
    );
  });
  if (shoe[0] !== undefined) {
    return shoe[0].shoeSize;
  } else {
    return undefined;
  }
}
