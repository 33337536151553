import { EcomStyle, StyleType } from "common-types";

export const PRODUCT_PRIORITY: StyleType[] = [
  'Coat',
  'Pant',
  'Shirt',
  'Tie',
  'Vest',
  'Shoes',
  'PocketSquare',
  'Socks',
  'Suspenders',
  'Miscellaneous',
  'Jewelry',
];

/**
 * Returns a new array of styles sorted by product priority
 * @param styles The styles to be sorted
 * @returns
 */
export function sortStylesByProductPriority<T extends EcomStyle[] | undefined>(styles: T): T {
  if (styles === undefined) {
    console.error("styles is undefined.  Cannot sort styles.");
    return styles;
  }
  const newStyles = [...styles].sort((a, b) => {

    for (let i = 0; i < PRODUCT_PRIORITY.length; i++) {
      if (a.styleType === PRODUCT_PRIORITY[i]) {
        return -1;
      }
      if (b.styleType === PRODUCT_PRIORITY[i]) {
        return 1;
      }
    }
    return 1;
  });
  return newStyles as T;
}
