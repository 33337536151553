import { Event, EventMember } from 'common-types';
import { MembersDif } from './types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';


export function getMembersDif(previous: EventMember[] | undefined, current: EventMember[] | undefined, options?: DifOptions<'members'>): MembersDif {

  const currentEvent = {
    members: current
  } as Event;

  const previousEvent: Event = {
    members: previous
  } as Event;

  return getDifByKeyFromEvent(previousEvent, currentEvent, 'members', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);


  // return getDif(previous, current, EVENT_ITEM_ID_KEYS['members'], EVENT_ITEM_ID_KEYS, 0, 'members', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const members = [] as EventMember[];

// getMembersDif(members, members, { keysToIgnore: ['memberLook'] });

