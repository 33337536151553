import { convertDateToUnixTimestamp, getMinDateForOnlineEvent } from 'business-logic';
import { EventNameSchema, EventSchema } from 'common-types';
import { z } from 'zod';
import { EVENT_DATE_TOO_SOON_MESSAGE } from './event-date-too-soon-message';


export const DealerEventDetailsFormSchema = z.object({
  eventName: EventNameSchema
    .or(z.literal(''))
    .refine(val => val.length > 0, { message: 'Event name is required' }), // Necessary because '' is an allowed value
  eventType: EventSchema.shape.eventType
    .or(z.literal('')),
  eventDate: EventSchema.shape.eventDate
    .refine(val => val >= convertDateToUnixTimestamp(getMinDateForOnlineEvent().toDateString()), { message: EVENT_DATE_TOO_SOON_MESSAGE })
    .refine(val => val !== 0, { message: 'Event date is required' }),
  //   eventDate: z.object({
  //     date: z.date()
  //   })
  //     .transform(({ date }) => convertDateToUnixTimestamp(date.toDateString()))
  //     // .superRefine((val, ctx) => {
  //     //   console.log("val in superRefine: ", val);

  //     //   const parsed = EventSchema.shape.eventDate.safeParse(val);
  //     //   if (!parsed.success) {
  //     //     const message = parsed.error.issues[0].message;
  //     //     console.log({ parsed, message });
  //     //     ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: true, message })
  //     //   }
  //     // })
  //   // .optional(),
})



export type DealerEventDetailsForm = z.infer<typeof DealerEventDetailsFormSchema>;


/** This is needed to make the respective organizer fields required.  */
export const RequiredAdminSchema = EventSchema.shape.admins.element.extend({
  firstName: EventSchema.shape.admins.element.shape.firstName,
  lastName: EventSchema.shape.admins.element.shape.lastName,
  email: EventSchema.shape.admins.element.shape.email,
}).required();

const phoneRegex = /^\d{10}$/;


export const DealerOrganizerDetailsFormSchema = z.object({
  // eventType: z.enum([EventType.Wedding, EventType.Quinceañera]),
  organizerFirstName: RequiredAdminSchema.shape.firstName,
  // .or(z.literal(''))
  // .refine(val => val.length > 0, { message: 'First Name is required' }), // Necessary because '' is an allowed value
  organizerLastName: RequiredAdminSchema.shape.lastName,
  // .or(z.literal(''))
  // .refine(val => val.length > 0, { message: 'Last Name is required' }), // Necessary because '' is an allowed value
  // organizerRole: EventSchema.shape.admins.element.shape.role.or(z.literal('')),
  organizerPhone: RequiredAdminSchema.shape.phone
    .regex(phoneRegex, { message: 'Phone requires 10 digits' })
    .refine(val => val && val.length > 0, { message: 'Phone is required' }),
  organizerEmail: RequiredAdminSchema.shape.email
  // .or(z.literal(''))
  // .refine(val => val.length > 0, { message: 'Email is required' }), // Necessary because '' is an allowed value
});

export type DealerOrganizerDetailsForm = z.infer<typeof DealerOrganizerDetailsFormSchema>;
