import { EcomStyle, StyleGroup } from 'common-types';

export function canRentStyle(ecomStyle: EcomStyle): boolean {
  const { styleGroup, companionStylePrice } = ecomStyle;
  let hasBuyOption: boolean = false;
  if (styleGroup === StyleGroup.Rental && ecomStyle.price) {
    hasBuyOption = true;
  } else if (
    styleGroup === StyleGroup.Purchase &&
    companionStylePrice !== null &&
    companionStylePrice !== undefined
  ) {
    hasBuyOption = true;
  }
  return hasBuyOption;
  // if (getStyleRentPrice(ecomStyle) !== undefined && hasRentOption) {
  //   return true;
  // }

  // return false;
}
