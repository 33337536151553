import {
  Commission,
  CommissionDetail,
  CommissionTypeEnum,
  Order,
} from 'common-types';
import { convertLegacyDateToUnixTimestamp } from '../../date';
import { getCommission } from './get-commission';
import { getCommissionDetails } from './get-commission-details';
import { getRentalCommission } from './get-rental-commission';
import { getResaleCommission } from './get-resale-commission';

export function convertOrderToCommission(order: Order): Commission {
  let commissionDetails: Array<CommissionDetail> = getCommissionDetails(order);
  let commission: Commission = {
    commissionAccountNumber: order.commissionAccountNumber,
    productionWeek: order.productionWeek,
    productionWeekUnix: convertLegacyDateToUnixTimestamp(order.productionWeek),
    eventDescription: order.eventDescription,
    eventId: order.eventId,
    eventDate: convertLegacyDateToUnixTimestamp(order.useDate),
    orderId: order.documentId,
    customer: {
      firstName: order.customer.firstName,
      lastName: order.customer.lastName,
    },
    accountNumber: order.accountNumber,
    orderNumber: order.orderNumber,
    orderStatus: order.orderStatus,
    commissionType: order.commissionType ?? CommissionTypeEnum.Referral, // need to pull from order.
    rentalCommission: getRentalCommission(order),
    purchaseCommission: getResaleCommission(order),
    totalCommission: getCommission(order),
    transferredByDealer: order.transferredByDealer ?? '',
    details: commissionDetails,
  };
  if (order.promos !== undefined) {
    commission.promos = order.promos;
  }
  if (order.promoDetails !== undefined) {
    commission.promoDetails = order.promoDetails;
  }
  return commission;
}
