import { Event, EventMember } from "common-types";
import { sortStylesByProductPriority } from '../../../style/utilities/ecommerce/sort-styles-by-product-priority';


/**
 * Sorts the styles in the member look by product priority and returns a new event with the sorted member looks.
 * @param event The event to sort the member looks for
 */
export function sortEventMemberLookStyles(event: Event | undefined): Event | undefined {
  if (event === undefined) {
    console.error("Event is undefined.  Cannot sort event looks.");
    return;
  }
  const newMembers = event.members.map((member) => {
    const { memberLook } = member;
    if (memberLook === undefined) {
      return member;
    }
    const newStyles = sortStylesByProductPriority(memberLook.styles);
    const newMember: EventMember = {
      ...member,
      memberLook: {
        ...memberLook,
        styles: newStyles
      }
    };
    return newMember;
  });
  return { ...event, members: newMembers };
}
