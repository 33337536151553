import { Event, Look } from 'common-types';
import { LooksDif } from './types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';

export function getLooksDif(previous: Look[] | undefined, current: Look[] | undefined, options?: DifOptions<'looks'>): LooksDif {

  const currentEvent = {
    looks: current
  } as Event;

  const previousEvent: Event = {
    looks: previous
  } as Event;

  return getDifByKeyFromEvent(previousEvent, currentEvent, 'looks', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

  // return getDif(previous, current, EVENT_ITEM_ID_KEYS['looks'], EVENT_ITEM_ID_KEYS, 0, 'looks', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const looks = [] as Look[];

// getLooksDif(looks, looks, { keysToIgnore: ['altColors'] });
