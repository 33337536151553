import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { defer, map, of, skipWhile, startWith } from 'rxjs';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
})
export class PhoneFormComponent {
  constructor(private formBuilder: FormBuilder) {}

  form = this.formBuilder.nonNullable.group({
    phone: ['', Validators.pattern(/^\d{10}$/)],
  });

  @Input() required = true;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() textAlign: string = '';
  @Input() label = 'Phone #';
  @Input() set disabled(disabled: boolean | { disabled: boolean, emitEvent: boolean }) {
    console.log("disabled", disabled);
    if (typeof disabled === 'object') {
      this.form.disable({ emitEvent: disabled.emitEvent });
    } else {
      if (disabled) this.form.disable();
      else this.form.enable();
    }
  };

  @Input()
  set initialValue(value: string | undefined) {
    this.form.patchValue(
      {
        phone: value,
      },
      { emitEvent: false }
    );
  }

  @Output() formReady = of(this.form);

  @Output()
  valueChange = defer(() =>
    this.form.valueChanges.pipe(
      // tap((formValue) => console.log('formValue changed in phone-form:', formValue)),
      startWith(this.form.value),
      skipWhile((result) => result.phone === ''),
      map((formValue) => formValue.phone)
    )
  );

  @Output() focus: EventEmitter<any> = new EventEmitter();

  onInputFocus() {
    this.focus.emit();
  }
}
