import { Change, ChangeType, MappedTypes, SupportedTypes } from "../types";

export function isDifCreate<T extends SupportedTypes>(dif: Change<T> | null): dif is { previous: typeof ChangeType.didNotExist, current: Partial<MappedTypes<T>>, } {
  return dif !== null && dif.previous === ChangeType.didNotExist && dif.current !== ChangeType.removed;
}

export function isDifDelete<T extends SupportedTypes>(dif: Change<T> | null): dif is { previous: Partial<MappedTypes<T>>, current: typeof ChangeType.removed } {
  return dif !== null && dif.previous !== ChangeType.didNotExist && dif.current === ChangeType.removed;
}

export function isDifUpdate<T extends SupportedTypes>(dif: Change<T> | null): dif is { previous: Partial<MappedTypes<T>>, current: Partial<MappedTypes<T>>, } {
  return dif !== null && dif.previous !== ChangeType.didNotExist && dif.current !== ChangeType.removed;
}

export function isDifNoChange<T extends SupportedTypes>(dif: Change<T> | null): dif is null {
  return dif === null;
}



// const dif: Change<Event> = {
//   previous: ChangeType.didNotExist,
//   current: {
//     eventName: "event",
//   }
// }


// if (isDifModified(dif)) {
//   dif.previous
//   console.log("Dif is a change that represents an addition");

// }
