import { Measurements } from 'common-types';
import { getLegacyCoatSize } from './index';

export function getLegacyPantRise(measurements: Measurements): string {
  let coatSize = '';
  let coatLength = '';
  if (measurements.coatSize !== undefined) {
    [coatSize, coatLength] = getLegacyCoatSize(measurements.coatSize);
  }
  if (measurements.waist !== undefined && measurements.outseam !== undefined) {
    if (measurements.waist < 27) {
      // Mens waists start at 27.
      return 'B';
    }
    if (measurements.outseam < 39) {
      return 'S';
    } else if (measurements.outseam >= 39 && measurements.outseam <= 40) {
      if (coatSize === 'R') {
        return 'R';
      } else {
        return 'S';
      }
    } else if (measurements.outseam > 40 && measurements.outseam <= 44) {
      if (coatSize === 'L' && measurements.outseam >= 43.5) {
        return 'L';
      } else {
        return 'R';
      }
    } else {
      // longer than 44.
      if (coatSize === 'L' && measurements.outseam <= 47) {
        return 'L';
      } else {
        return 'X';
      }
    }
  } else {
    throw new Error(
      'Waist and outseam must be set in order to obtain a pant rise.',
    );
  }
}
