import { EcomEnsemble } from 'common-types';

export const shouldModifyElasticEnsemble = (
  ensemble: EcomEnsemble,
): boolean => {
  if (ensemble.ensembleGroup === 'Rental') {
    return true;
  }
  if (
    ensemble.ensembleGroup === 'Purchase' &&
    !ensemble.companionEnsembleCode
  ) {
    return true;
  }
  return false;
};
