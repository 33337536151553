import { Event } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';

export function getLooksDifFromEvent<T extends Event | undefined>(previous: T, current: T, options?: DifOptions<'looks'>) {

  return getDifByKeyFromEvent(previous, current, 'looks', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const event: Event = {} as Event;

// getLooksDifFromEvent(event, event, { keysToIgnore: ['altColors'] });

