import {
  CommissionDetail,
  CommissionTypeEnum,
  OrderDetail,
} from 'common-types';

export function getPurchaseCommission(
  orderDetail: OrderDetail,
  commissionType: CommissionTypeEnum,
): CommissionDetail {
  const { replacementCode } = orderDetail;
  // CustomLink & Organic does not get commission on individual items except the coat
  if (replacementCode) {
    return {
      styleGroup: orderDetail.styleGroup,
      price: 0,
      replacement: true,
      legacyStyleCode: orderDetail.style,
      styleType: orderDetail.productId,
      lineStatus: orderDetail.lineStatus,
      commission: 0.0,
      warehouse: orderDetail.warehouse,
      avalaraLineId: orderDetail.avalaraLineId ?? '',
    };
  } else {
    switch (commissionType) {
      case CommissionTypeEnum.Organic:
        if (orderDetail.productId === 'CT') {
          return {
            styleGroup: orderDetail.styleGroup,
            price: orderDetail.price ?? 0,
            replacement: false,
            legacyStyleCode: orderDetail.style,
            styleType: orderDetail.productId,
            lineStatus: orderDetail.lineStatus,
            commission: 15.0,
            warehouse: orderDetail.warehouse,
            avalaraLineId: orderDetail.avalaraLineId ?? '',
          };
        } else {
          return {
            styleGroup: orderDetail.styleGroup,
            price: orderDetail.price ?? 0,
            replacement: false,
            legacyStyleCode: orderDetail.style,
            styleType: orderDetail.productId,
            lineStatus: orderDetail.lineStatus,
            commission: 0.0,
            warehouse: orderDetail.warehouse,
            avalaraLineId: orderDetail.avalaraLineId ?? '',
          };
        }
      case CommissionTypeEnum.CustomLink:
        if (orderDetail.productId === 'CT') {
          return {
            styleGroup: orderDetail.styleGroup,
            price: orderDetail.price ?? 0,
            replacement: false,
            legacyStyleCode: orderDetail.style,
            styleType: orderDetail.productId,
            lineStatus: orderDetail.lineStatus,
            commission: 25.0,
            warehouse: orderDetail.warehouse,
            avalaraLineId: orderDetail.avalaraLineId ?? '',
          };
        } else {
          return {
            styleGroup: orderDetail.styleGroup,
            price: orderDetail.price ?? 0,
            replacement: false,
            legacyStyleCode: orderDetail.style,
            styleType: orderDetail.productId,
            lineStatus: orderDetail.lineStatus,
            commission: 0.0,
            warehouse: orderDetail.warehouse,
            avalaraLineId: orderDetail.avalaraLineId ?? '',
          };
        }
      case CommissionTypeEnum.Referral:
        return {
          styleGroup: orderDetail.styleGroup,
          price: orderDetail.price ?? 0,
          replacement: false,
          legacyStyleCode: orderDetail.style,
          styleType: orderDetail.productId,
          lineStatus: orderDetail.lineStatus,
          commission:
            orderDetail.lineStatus === 'D'
              ? 0.0
              : parseFloat(((orderDetail.price ?? 0) * 0.1).toFixed(2)),
          warehouse: orderDetail.warehouse,
          avalaraLineId: orderDetail.avalaraLineId ?? '',
        };
      default:
        return {
          styleGroup: orderDetail.styleGroup,
          price: 0,
          replacement: false,
          legacyStyleCode: orderDetail.style,
          styleType: orderDetail.productId,
          lineStatus: orderDetail.lineStatus,
          commission: 0.0,
          warehouse: orderDetail.warehouse,
          avalaraLineId: orderDetail.avalaraLineId ?? '',
        };
    }
  }
}
