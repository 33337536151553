import { Event } from 'common-types';
import { AdminsDif } from './types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDifByKeyFromEvent } from './utils';

export function getAdminsDifFromEvent<T extends Event | undefined>(previous: T, current: T, options?: DifOptions<'admins'>): AdminsDif {

  return getDifByKeyFromEvent(previous, current, 'admins', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const event: Event = {} as Event;

// getAdminsDifFromEvent(event, event, {
//   keysToIgnore: ['isOrganizer'],
//   keysToInclude: ['isOrganizer']
// });

