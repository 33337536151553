
import { Event } from 'common-types';
import { EVENT_ITEM_ID_KEYS } from '../constants';
import { Change, CreateDif, DeleteDif, Dif, DifType, UpdateDif } from '../types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from '../types/dif-options.type';
import { getDif, isDifCreate, isDifDelete, isDifNoChange, isDifUpdate } from './';

export function getDifByKeyFromEvent<T extends Event>(previous: T | undefined, current: T | undefined, key: keyof Event | 'event', options: DifOptions = DEFAULT_DIF_OPTIONS): Dif<Change<T>> | null {

  const previousToCheck = previous ? key === 'event' ? previous : previous[key] : undefined;
  const currentToCheck = current ? key === 'event' ? current : current[key] : undefined;

  const itemIdKey = key in EVENT_ITEM_ID_KEYS ? EVENT_ITEM_ID_KEYS[key as keyof typeof EVENT_ITEM_ID_KEYS] : undefined;
  console.log('eventItemIdKey: ', itemIdKey);
  // return getDif(previous[key], current[key], eventItemIdKey, eventItemIdKeys, 0, key, (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

  console.log(`********** BEGIN ${key.toUpperCase()} DIF **********`);
  console.log('with options: ', options);
  const { keysToInclude, keysToIgnore } = options ?? DEFAULT_DIF_OPTIONS
  if (keysToInclude) {
    console.warn("Only checking the following keys: ", keysToInclude);
  }
  if (keysToIgnore) {
    console.warn("Ignoring the following keys: ", keysToIgnore);
  }
  console.time('getEventDif elapsed time');
  const dif = getDif(previousToCheck, currentToCheck, itemIdKey, EVENT_ITEM_ID_KEYS, 0, key, (options ?? DEFAULT_DIF_OPTIONS) as DifOptions) as Change<T>;
  console.log('dif: ', dif);
  if (isDifNoChange(dif)) {
    console.log(`No differences found between the two ${key}`);
    console.log(`********** END ${key.toUpperCase()} DIF **********`);
    console.timeEnd('getEventDif elapsed time');
    return null;
  }
  if (isDifUpdate(dif)) {
    type CurrentDif = typeof dif.current;
    const modified = Object.keys(dif.current) as (keyof (typeof dif.current))[];
    console.log(`${key.toUpperCase()} was modified `, modified);
    // for (const key in dif.current) {
    //   if (dif.current[key as keyof typeof dif.current]) {
    //     console.log(`Event ${key} was modified`);
    //   }
    // }
    console.log(`********** END ${key.toUpperCase()} DIF (UPDATE) **********`);
    console.timeEnd('getEventDif elapsed time');
    const updateDif: UpdateDif<Change<T>> = {
      difType: DifType.update,
      previous: dif.previous,
      current: dif.current,
      modified
    };
    return updateDif;
  }

  if (isDifCreate(dif)) {

    for (const key in dif.current) {
      if (dif.current[key as keyof typeof dif.current]) {
        console.log(`${key} added`);
      }
    }
    console.log(`********** END ${key.toUpperCase()} DIF (CREATE) **********`);
    console.timeEnd('getEventDif elapsed time');
    const createDif: CreateDif<Change<T>> = {
      difType: DifType.create,
      previous: null,
      current: dif.current,
    };
    return createDif;
  }

  if (isDifDelete(dif)) {
    for (const key in dif.previous) {
      if (dif.previous[key as keyof typeof dif.previous]) {
        console.log(`${key} removed`);
      }
    }
    console.log(`********** END ${key.toUpperCase()} DIF (DELETE) **********`);
    console.timeEnd('getEventDif elapsed time');
    const deleteDif: DeleteDif<Change<T>> = {
      difType: DifType.delete,
      previous: dif.previous,
      current: null,
    };
    return deleteDif;
  }

  /// This should never happen
  console.warn(`********** END ${key.toUpperCase()} DIF (SHOULDN'T HAPPEN) **********`);
  console.timeEnd('getEventDif elapsed time');
  return null;


}

// const event: Event = {} as Event;

// getAdminsDifFromEvent(event, event, {
//   keysToIgnore: ['isOrganizer'],
//   keysToInclude: ['isOrganizer']
// });

